#root {
    display: flex!important;
    flex-direction: column;
    height: 100%;
    height: 100vh;
}

.App-Body {
    overflow: auto;
    overflow-x: hidden;
    flex: 1;
}

.is-height-0 {
    height: 0
}

.is-size-8 {
    font-size: 0.65rem!important;
}

.is-flex-centered {
    display: flex!important;
    justify-content: center;
    align-items: center;
}

.is-fullheight {
    height: 100%;
}

.has-text-info {
    color: #0e76a8;
}

.is-atleast-fullheight {
    min-height: 100%;
}

.panel.is-atleast-fullheight {
    margin-bottom: 0.5em;
}

.footer {
    background-color: inherit;
    padding: 1rem 1rem 1.2rem;
    font-size: 0.8em;
    color: #888
}

@media screen and (max-width: 1023px) {
    .navbar-item.is-active.is-tab {
        border-bottom-color: transparent;
        border-bottom-width: 0;
        border-left-width: 0.5em;
        border-left-color: #3273dc;
        border-left-style: solid;
    }
    .navbar-menu {
        height: 100%;
        overflow: auto;
    }
}

.navbar-item .button.is-rounded {
    border-radius: 8em;
    padding: 0.8em
}

.list-item {
    font-size: 0.8em;
}

.list-item .card:hover {
    box-shadow: 0 0.5em 1em 0.3px #00000033
}

.list-item-title {
    font-weight: bold;
    color: 'black'
}

.list-item-subtitle {
    font-size: 0.8em;
}

.job-item.card {
    border-bottom: 0.2px solid #aaa4;
}

@media screen and (max-width: 768px) {
    .message-item .name-bar>div:first-child {
        display: flex;
        flex: 1!important;
    }
}

.message-item.card.is-active,
.message-item.card:hover,
.job-item.card.is-active,
.job-item.card:hover {
    background-color: white!important;
}

.job-item.card.is-active {
    border-left: solid #0e76a8!important;
    background-color: #f2f6f7!important;
}

.job-detail.card {
    display: flex;
    flex-direction: column;
    flex: 1
}

.job-detail.card .card-content {
    display: flex;
    flex-direction: column;
    flex: 1
}

.job-panel.panel {
    flex-direction: column;
}

.job-panel.panel .panel-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width:1024px) {
    .job-panel.panel>*:first-child,
    .job-panel.panel {
        border-top-right-radius: 0;
    }
    .job-panel.panel>*:last-child,
    .job-panel.panel {
        border-bottom-right-radius: 0;
    }
    .job-detail,
    .job-detail .card-content .level {
        border-top-right-radius: 1em;
        border-bottom-right-radius: 1em;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .job-detail .card-content .level {
        border-bottom-right-radius: 0;
    }
}

@media screen and (max-width:1023px) {
    .job-detail {
        border-radius: 0 0 1em 1em;
    }
    .job-panel.panel {
        margin-bottom: 8em!important;
    }
}

.job-detail .card-content .level {
    box-shadow: 0 0.125em 0.25em rgba(10, 10, 10, .1);
}

.image.is-80x80 {
    height: 80px;
    width: 80px;
}

@media screen and (min-width: 769px) {
    .modal-card,
    .modal-content {
        margin: 0 auto;
        max-height: calc(100vh - 40px);
        width: auto;
    }
}

.job-form-field.field {
    border-left: solid grey 2px;
    padding-left: 1em;
}

.job-form-field.field:hover {
    border-left: solid #3298dc 2px;
}

.is-active .settings-link {
    border-bottom: solid 2px #3298dc;
}

.settings-link:hover {
    background-color: #dadada;
}

.settings-detail {
    box-shadow: -0.9px 0 1px #0005;
    padding-top: 1em;
    padding-bottom: 1em;
}

@media screen and (max-width: 768px) {
    .settings-detail {
        box-shadow: none;
    }
}

.settings-detail .columns {
    border-top: #aaa solid 1px;
}

.settings-detail .columns:last-of-type {
    border-bottom: #aaa solid 1px;
}