@import "../node_modules/bulma/css/bulma.min.css";
@import "../node_modules/animate.css/animate.min.css";
body,
 :root,
html {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    height: 100vh;
    width: 100%;
    width: 100vw;
    overflow: hidden;
    text-align: center;
    user-select: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.button.is-white.is-loading::after {
    border-color: transparent transparent #fff #fff!important;
}

.spinner {
    animation: spin linear 16s infinite
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}